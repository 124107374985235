import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import styles from './preview-blocker.scss';

const OFFSET = 20;

// we block user from doing any click on preview, just because it doesn't work well
const PreviewBlocker: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [coords, setCoords] = React.useState<{ x: any; y: any }>({ x: null, y: null });
  const [position, setPosition] = React.useState({});
  const floaterRef = React.useRef<HTMLElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const t = useTranslate();

  React.useLayoutEffect(() => {
    if (coords.y) {
      const cRect = containerRef?.current?.getBoundingClientRect();
      const fRect = floaterRef?.current?.getBoundingClientRect();
      if (cRect && fRect) {
        const isPositionedLeft = cRect.right - coords.x > 150;
        setPosition({
          top: coords.y - (cRect ? cRect.y : 0) + OFFSET,
          [isPositionedLeft ? 'left' : 'right']: isPositionedLeft
            ? isMobile
              ? Math.min(coords.x, 200)
              : coords.x - (cRect ? cRect.x : 0) - OFFSET / 2
            : cRect.right - coords.x - OFFSET / 2,
        });
      }
    }
  }, [coords]);

  return (
    <div
      ref={containerRef}
      className={styles.container}
      title={t('preview-blocker.title')}
      onClick={(ev) =>
        setCoords({
          x: ev.clientX,
          y: ev.clientY,
        })
      }
    >
      {coords.y && (
        <span ref={floaterRef} className={styles.floatingText} style={{ ...position }}>
          {t('preview-blocker.popup')}
        </span>
      )}
    </div>
  );
};

export default PreviewBlocker;
