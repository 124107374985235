/* eslint-disable no-unused-vars, camelcase */
import { RawMediaData } from '../../../common/common-types';
import { MediaType, MediaPrivacy } from './upload-types';

export const uploadMediaFile =
  ({ instance, folderId }: { instance: string; folderId?: string | null }) =>
  async ({
    file,
    type,
    privacy = 'public',
  }: {
    file: File;
    type: MediaType;
    privacy?: MediaPrivacy;
  }): Promise<RawMediaData> => {
    const params = new URLSearchParams({ mediaType: type, privacy });
    const response = await fetch(
      `/_serverless/reviews-media-services/upload-url?${params.toString()}`,
      {
        headers: { Authorization: instance },
      },
    );
    const uploadUrl: string = (await response.json()).uploadUrl;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('media_type', type);
    formData.append('acl', privacy);
    if (folderId) {
      formData.append('parent_folder_id', folderId);
    }
    const request = new XMLHttpRequest();
    return new Promise<RawMediaData>((resolve, reject) => {
      try {
        request.responseType = 'json'; // IE11 does not support responseType json
      } catch (e) {}
      request.addEventListener('load', (event) => {
        const eventTarget = event.target as XMLHttpRequest;
        let data = eventTarget?.response[0];
        const { error_description } = eventTarget?.response;

        if (error_description) {
          reject(eventTarget.response);
        }

        try {
          data = JSON.parse(eventTarget?.response)[0];
        } catch (e) {}
        // @TODO: parse media data to match exact type

        resolve(data);
      });
      request.addEventListener('error', reject);
      request.open('POST', uploadUrl);
      request.send(formData);
    });
  };
