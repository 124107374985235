export const ROOT = 'wr-root';
export const ROOT_CSR_READY = 'wr-root-csr-ready';

// Header
export const APP_TITLE = 'wr-app-title';
export const HEADER_TITLE = 'wr-header-title';
export const HEADER_SUBTITLE = 'wr-header-subtitle';
export const CREATE_REVIEW_BUTTON = 'wr-create-review-button';
export const VIEW_REVIEW_BUTTON = 'wr-view-review-button';
export const AVERAGE_RATING = 'wr-average-rating';
export const SORTING_SELECTOR = 'wr-sorting-selector';
export const FILTER_SELECTOR = 'wr-filter-selector';
export const REVIEW_BOX = 'review-box';
export const REPLY_BOX = 'reply-box';
export const REPLY_TEXT_AREA = 'reply-text-area';
export const REVIEW_BOX_TOP = 'review-box-top';
export const MOBILE_FORM = 'mobile-form';
export const NAME_FIELD = 'name-field';
export const EMAIL_FIELD = 'email-field';
export const RATING_FIELD = 'rating-field';
export const TITLE_FIELD = 'title-field';
export const BODY_FIELD = 'body-field';
export const MEDIA_FIELD = 'media-field';
export const MEDIA_SECTION_ERROR = 'media-section-error';
export const MEDIA_INPUT_PENDING = 'media-input-pending';
export const MEDIA_INPUT_THUMBNAIL = 'media-input-thumbnail';
export const MEDIA_INPUT_THUMBNAIL_DELETE_BTN = 'media-input-thumbnail-delete-btn';
export const FORM_ERROR = 'form-error';
export const SORT_FILTER_BTN = 'sort-filter-btn';
export const SORT_FILTER_UPDATE_BTN = 'sort-filter-update-btn';
export const FILTER_RADIO_GROUP = 'filter-radio-group';
export const SORT_RADIO_GROUP = 'sort-radio-group';
export const REVIEWS_BREAKDOWN = 'reviews-breakdown';

// Section notification
export const SECTION_NOTIFICATION_CTA = 'section-notification-cta';

// Empty states
export const EMPTY_STATE_ZERO_REVIEWS_TITLE = 'es-zero-reviews-title';
export const EMPTY_STATE_ZERO_REVIEWS_CONTENT = 'es-zero-reviews-content';
export const EMPTY_STATE_CTA = 'es-zero-reviews-cta';
export const EMPTY_STATE_FILTERED_TO_EMPTY = 'es-filtered-to-empty';

export const LIST_ITEM = 'list-item';
export const PENDING = 'pending';
export const READY = 'ready';
export const DELETED = 'deleted';
export const REPLY = 'reply';
export const ERROR = 'error';
export const MARKED = 'marked';

export const REPLY_AUTHOR = 'reply-author';
export const REPLY_DATE = 'reply-date';
export const REPLY_CONTENT = 'reply-content';
// Review
export const REVIEW_CONTENT = 'review-content';
export const REVIEW_AUTHOR = 'review-author';
export const REVIEW_RATING = 'review-rating';
export const REVIEW_STATUS_BADGE = 'review-status-badge';
export const REVIEW_TITLE = 'review-title';
export const REVIEW_BODY = 'review-body';
export const REVIEW_MEDIA = 'review-media';

export const MEDIA_MODAL = 'media-modal';
export const MEDIA_THUMBNAIL = 'media-thumbnail';
export const MEDIA_SHOW_MORE = 'media-show-more';
export const PRIMARY_BTN = 'primary-btn';
export const SECONDARY_BTN = 'secondary-btn';
export const CANCEL_BTN = 'cancel-btn';
export const CONFIRMATION_MODAL = 'confirmation-modal';
export const ACTIONS_MENU = 'actions-menu';
export const ACTIONS_MENU_BTN = 'actions-menu-btn';
export const ACTIONS_MENU_EDIT_BTN = 'actions-menu-edit-btn';
export const ACTIONS_MENU_DELETE_BTN = 'actions-menu-delete-btn';
export const ACTIONS_MENU_SHARE_BTN = 'actions-menu-share-btn';
export const TOAST = 'wr-toast';
export const REPLY_BTN = 'reply-btn';
export const HELPFUL_BTN = 'helpful-btn';
export const NOT_HELPFUL_BTN = 'not-helpful-btn';
export const HELPFUL_COUNT = 'helpful-count';
export const NOT_HELPFUL_COUNT = 'not-helpful-count';
export const REACTION_MENU = 'reaction-menu';
export const SPINNER = 'spinner';
export const REVIEW_LIST = 'review-list';
export const REVIEW_LIST_CONTAINER = 'review-list-container';
export const LIST_LOADING = 'list-loading';
export const MOBILE_FORM_MINIMIZE_BTN = 'mobile-form-minimize-btn';
export const MOBILE_FORM_MAXIMIZE_BTN = 'mobile-form-maximize-btn';
export const MOBILE_FORM_TOOLBAR_TOGGLE = 'mobile-form-toolbar-toggle';
export const SHOW_MORE_BTN = 'show-more-btn';
export const NEXT_PAGE_BTN = 'next-page-btn';
export const PREV_PAGE_BTN = 'prev-page-btn';
export const SEE_ALL_REVIEWS_BTN = 'see-all-reviews-btn';
export const TIME_AGO = 'time-ago';
export const VIDEO_PLAYER_ELEMENT = 'video_container-video-player-element';
export const PLAY_TRIANGLE = 'play-triangle';

export const REVIEW_FOOTER_ERROR_EDIT = 'review-footer-error-edit';
export const REVIEW_FOOTER_ERROR_RETRY = 'review-footer-error-retry';

export const CRASH_STATE_MESSAGE = 'crash-state-message';
