import { useApi } from '~reviews/Widget/components/api-provider/use-api';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { AllowedMediaTypes } from '~reviews/common/common-types';
import {
  FormSettings,
  MediaField,
  TextField,
} from '~reviews/common/store/configuration/configuration-types';

export const useFormSettings = (): FormSettings => {
  const t = useTranslate();
  const { title, body, media, customFieldNames } = useApi((state) => state.configuration);

  const buildTitleField = (): TextField => {
    const { maxLength: limit = 0, required = false } = title || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          label: customFieldNames?.title || t('field-label.title.default'),
          required,
          limit,
        }
      : {
          enabled: false,
        };
  };

  const buildBodyField = (): TextField => {
    const { maxLength: limit = 0, required = false } = body || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          label: customFieldNames?.body || t('field-label.body.default'),
          required,
          limit,
        }
      : {
          enabled: false,
        };
  };

  const buildMediaField = (): MediaField => {
    const { limit = 0, required = false } = media || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          allowedMedia: (customFieldNames?.media ?? 'all') as AllowedMediaTypes,
          required,
          limit,
        }
      : { enabled: false };
  };

  return {
    title: buildTitleField(),
    body: buildBodyField(),
    media: buildMediaField(),
    publishButtonText: customFieldNames?.publish || t('review-form.publish.default'),
    ctaButtonText: customFieldNames?.addReview || t('top-section.cta.default'),
    ratingLabel: customFieldNames?.rating || t('field-label.star-rating.default'),
  };
};
