import React from 'react';
import { ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { style, classes } from './submit-cancel.st.css';
import { PRIMARY_BTN, SECONDARY_BTN } from '~reviews/common/constants/data-hooks';
import { ButtonExtended } from '../../button-extended/button-extended';
import { useConfiguration } from '~reviews/Widget/components/configuration/use-configuration';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';

export const SubmitCancel: React.FC<{
  disableSubmit?: boolean;
  onCancel: () => void;
  isPending: boolean;
  isEdit: boolean;
  className?: string;
  buttonSize: 'medium' | 'tiny';
  publishButtonTextOverride?: string;
}> = ({
  disableSubmit,
  onCancel,
  isPending,
  isEdit,
  className,
  buttonSize,
  publishButtonTextOverride,
}) => {
  const t = useTranslate();
  const { publishButtonText } = useFormSettings();
  const { screenshotMode } = useConfiguration();
  return (
    <div className={style(classes.root, { isPending }, className)}>
      <ButtonExtended
        className={classes.cancelButton}
        disabled={isPending}
        onClick={onCancel}
        size={buttonSize}
        type="button"
        mapping="secondary"
        data-hook={SECONDARY_BTN}
      >
        {t('review-form.cancel')}
      </ButtonExtended>
      <ButtonExtended
        className={classes.submitButton}
        disabled={isPending || disableSubmit}
        size={buttonSize}
        data-hook={PRIMARY_BTN}
        type="submit"
        mapping="primary"
      >
        {isPending && (
          <ThreeDotsLoader className={classes.threeDotsLoader} isStatic={screenshotMode} />
        )}
        <span className={style(classes.submitButtonText)}>
          {isEdit ? t('review-form.save') : publishButtonTextOverride || publishButtonText}
        </span>
      </ButtonExtended>
    </div>
  );
};
