import React from 'react';
import {
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  IconButton,
  ButtonSize,
} from 'wix-ui-tpa/cssVars';
import Error from 'wix-ui-icons-common/on-stage/Error';
import Close from 'wix-ui-icons-common/on-stage/Close';
import { classes } from './form-error-notification.st.css';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';

export const FormErrorNotification: React.FC<{
  text: string;
  className?: string;
  onClose?: () => void;
  dataHook?: string;
}> = ({ text, className, onClose, dataHook }) => {
  const t = useTranslate();
  return (
    <SectionNotification type={NOTIFICATION_TYPE.error} className={className} data-hook={dataHook}>
      <SectionNotification.Icon icon={<Error aria-hidden="true" />} />
      <SectionNotification.Text className={classes.text}>{text}</SectionNotification.Text>
      {onClose && (
        <IconButton
          aria-label={t('close-button.aria-label')}
          size={ButtonSize.tiny}
          icon={<Close />}
          onClick={onClose}
        />
      )}
    </SectionNotification>
  );
};
