import React from 'react';
import { ThumbnailImage, Spinner, Tooltip } from 'wix-ui-tpa/cssVars';
import { style, classes } from './media-thumbnail.st.css';
import DeleteIcon from 'wix-ui-icons-common/on-stage/Delete';
import { uniqueId } from 'lodash';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { MEDIA_INPUT_THUMBNAIL_DELETE_BTN } from '~reviews/common/constants/data-hooks';
import { useConfiguration } from '~reviews/Widget/components/configuration/use-configuration';
import { useEnvironment } from '@wix/yoshi-flow-editor';

// On desktop the delete button is shown on hover/focus
// On mobile its shown only after focusing
export const MediaThumbnail: React.FC<{
  src?: string;
  isPending: boolean;
  onRemove: () => void;
  width: number;
  height: number;
  filename: string | undefined;
  dataHook?: string;
  type?: 'image' | 'video';
}> = ({ src, isPending, filename = '', onRemove, width, height, dataHook, type }) => {
  const { isMobile } = useEnvironment();
  const t = useTranslate();
  const { screenshotMode } = useConfiguration();

  const [isFocused, setIsFocused] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  // We track 'mousedown' state for 'delete' button because there is 'blur'
  // event of the container while pressing it, thus making the button disappear
  const [isPressed, setIsPressed] = React.useState(false);
  const isOpen = isFocused || isHovered || isPressed;

  const buttonId = React.useMemo(() => uniqueId('remove-btn'), []);
  return (
    <div
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onMouseEnter={() => {
        // When we have touch events whe get onMouseEnter with mouseDown.
        // This causes the 'delete' button clicked right at once, so we
        // disable this event for mobile devices
        if (!isMobile) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setIsHovered(false);
        }
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          onRemove();
        }
      }}
      data-hook={dataHook}
      tabIndex={0}
      role="group"
      aria-activedescendant={isOpen ? buttonId : undefined}
      aria-label={t('review.media-thumbnail-aria-label', { filename })}
      className={style(classes.root)}
    >
      {(isOpen || isPending) && <div aria-hidden={true} className={style(classes.overlay)} />}
      {isPending && (
        <div
          aria-label={t('empty-state.list-loading-text')}
          className={style(classes.spinnerContainer)}
        >
          <Spinner
            aria-hidden
            diameter={24}
            className={classes.spinner}
            isStatic={screenshotMode}
          />
        </div>
      )}
      {isOpen && (
        <div className={classes.deleteContainer}>
          <Tooltip content={t('review.media-input-remove-button')} appendTo="scrollParent">
            <div
              role="button"
              aria-label={t('review.media-input-remove-button')}
              id={buttonId}
              onClick={() => {
                onRemove();
              }}
              onMouseDown={() => setIsPressed(true)}
              onMouseUp={() => setIsPressed(false)}
              className={style(classes.deleteButton)}
              data-hook={MEDIA_INPUT_THUMBNAIL_DELETE_BTN}
            >
              <DeleteIcon />
            </div>
          </Tooltip>
        </div>
      )}
      {src ? (
        type === 'video' && src.startsWith('blob:') ? (
          <video src={src} width={width} height={height} />
        ) : (
          <ThumbnailImage aria-hidden width={width} height={height} src={src} />
        )
      ) : (
        <div style={{ width, height }} aria-hidden className={classes.thumbnailPlaceholder} />
      )}
    </div>
  );
};
