import React from 'react';
import ReviewForm from './review-form';
import { useApi } from '../api-provider/use-api';
import { REVIEW_BOX_TOP } from '../../../common/constants/data-hooks';
import {
  NewReviewFormState,
  ReviewContent,
} from '../../../common/store/reviews/reviews/review-state-types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { ROOT_FORM_CLASS } from '~reviews/common/services/id-utils';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';

export const DEFAULT_REVIEW_CONTENT: ReviewContent = {
  title: '',
  body: '',
  rating: 0,
  media: [],
};

const RootReviewForm: React.FC<{
  formState: NewReviewFormState;
  className?: string;
}> = ({ formState, className }) => {
  const { isEditor } = useEnvironment();
  const t = useTranslate();

  const {
    createReview,
    discardCreatingReview,
    biCancelCreatingReview,
    currentMember,
    biClickPublishReview,
    biFormValidationError,
    forceHoverState,
    forceErrorState,
  } = useApi((_state, actions) => {
    return {
      createReview: actions.createReview,
      discardCreatingReview: actions.discardCreatingReview,
      biCancelCreatingReview: actions.biCancelCreatingReview,
      currentMember:
        _state.members.currentOwner.type === 'MEMBER'
          ? _state.members.currentOwner.member
          : undefined,
      biClickPublishReview: actions.biClickPublishReview,
      biFormValidationError: actions.biFormValidationError,
      forceHoverState: formState.type === 'INITIAL' && formState.effects === 'Hover',
      forceErrorState: formState.type === 'INITIAL' && formState.effects === 'Error',
    };
  });

  if (formState.type === 'HIDDEN') {
    return null;
  }

  const initialContent = isEditor
    ? {
        ...DEFAULT_REVIEW_CONTENT,
        name: [currentMember?.contact?.firstName, currentMember?.contact?.lastName]
          .filter((n) => n)
          .join(' '),
        email: currentMember?.loginEmail ?? undefined,
        title: t('editor-field-input.placeholder-text'),
        body: t('editor-field-input.placeholder-text'),
      }
    : DEFAULT_REVIEW_CONTENT;

  return (
    <React.Fragment>
      <ReviewForm
        id="root-review-form"
        className={classNames(className, ROOT_FORM_CLASS)}
        dataHook={REVIEW_BOX_TOP}
        isEdit={false}
        isPending={formState.type === 'REVIEW_CREATE_PENDING'}
        isInList={false}
        initialContent={initialContent}
        error={formState.type === 'REVIEW_CREATE_ERROR' ? formState.error : undefined}
        forceHoverState={forceHoverState}
        forceErrorState={forceErrorState}
        onCancel={(_content, reset, isEmpty) =>
          isEmpty
            ? {
                type: 'SILENT_CANCEL',
                cleanup: () => {
                  reset();
                  discardCreatingReview({});
                  biCancelCreatingReview({ review: { content: _content }, isEdited: false });
                },
              }
            : {
                type: 'PROMPT',
                discard: 'COMMENT',
                onConfirm: () => {
                  reset();
                  discardCreatingReview({});
                  biCancelCreatingReview({ review: { content: _content }, isEdited: false });
                },
              }
        }
        onValidationFailed={(error) => {
          biFormValidationError({ missingRequiredFields: error ?? [] });
        }}
        {...(currentMember
          ? {
              requireContact: false,
              onSubmit: ({ content }) => {
                biClickPublishReview({ review: { content }, isEdited: false });
                createReview({
                  content,
                  createAs: { type: 'MEMBER', member: currentMember },
                });
              },
            }
          : {
              requireContact: true,
              onSubmit: ({ content, contact }) => {
                biClickPublishReview({ review: { content }, isEdited: false });
                createReview({
                  content,
                  createAs: {
                    type: 'VISITOR',
                    contact: { name: contact.name, email: contact.email },
                  },
                });
              },
            })}
      />
    </React.Fragment>
  );
};

export default RootReviewForm;
