import { min } from 'lodash';
import { PlatformImage } from '../../../common/store/reviews/reviews/review-state-types';

const blurParam = (blur: number) => (blur > 0 ? `blur_${blur},` : '');

const IMAGE_HOST = 'static.wixstatic.com';
const VIDEO_HOST = 'video.wixstatic.com';
export function getImageUrl(
  { id: fileName, width: actualWidth, height: actualHeight }: PlatformImage,
  {
    maxWidth = 1000,
    maxHeight = 1000,
    quality = 80,
    type = 'fit',
    format = 'png',
    blur = 0,
    mockImages,
  }: {
    maxWidth?: number;
    maxHeight?: number;
    quality?: number;
    type?: 'fit' | 'fill';
    format?: string;
    blur?: number;
    mockImages?: boolean;
  },
): string {
  if (!fileName) {
    // @TODO: some default?
    return '';
  }
  const [width, height] = [min([maxWidth, actualWidth]), min([maxHeight, actualHeight])];

  return mockImages
    ? createMockedImageUrl(fileName)
    : `https://${IMAGE_HOST}/media/${fileName}/v1/${type}/w_${width},h_${height},${blurParam(
        blur,
      )}al_c,q_${quality}/file.${format}`;
}

export function getVideoUrl(url: string): string {
  return url?.startsWith('https://') || url?.startsWith('http://')
    ? url
    : `https://${VIDEO_HOST}/${url}`;
}

export function getVideoPosterUrl(
  videoPoster: PlatformImage,
  {
    maxWidth = 1000,
    maxHeight = 1000,
    quality = 80,
    type = 'fit',
    format = 'png',
    blur = 0,
  }: {
    maxWidth?: number;
    maxHeight?: number;
    quality?: number;
    type?: 'fit' | 'fill';
    format?: string;
    blur?: number;
  },
): string {
  if (!videoPoster) {
    // @TODO: some default?
    return '';
  }
  const { url, width: actualWidth, height: actualHeight } = videoPoster;
  const [width, height] = [min([maxWidth, actualWidth]), min([maxHeight, actualHeight])];
  return `${
    url?.startsWith('https://') || url?.startsWith('http://') ? url : `https://${IMAGE_HOST}/${url}`
  }/v1/${type}/w_${width},h_${height},${blurParam(blur)}al_c,q_${quality}/file.${format}`;
}

const createMockedImageUrl = (filename: string): string => {
  const index = stringToIndex(filename);
  return `${window.location.origin}${window.location.pathname.replace(
    '/iframe.html',
    '',
  )}/number-${index}.webp`;
};

// currently we support 5 photos
const stringToIndex = function (str: string) {
  const maxPhotos = 5;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }
  return (hash % maxPhotos) + 1;
};
