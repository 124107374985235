import React from 'react';
import TextExtended from '../text-extended/text-extended';
import { useConfiguration } from '../configuration/use-configuration';
import { TIME_AGO } from '~reviews/common/constants/data-hooks';
import { createRtfFormatter, useEnvironment } from '@wix/yoshi-flow-editor';

const weekAgo = () => new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
const isLessThanWeek = (date: Date) => new Date(date).getTime() > weekAgo();
const isNotCurrentYear = (date: Date) => new Date().getFullYear() !== new Date(date).getFullYear();

const formatShortDate = (language: string, date: Date) => {
  return Intl.DateTimeFormat(language, { month: 'short', day: '2-digit' }).format(date);
};

const formatFullDate = (language: string, date: Date) => {
  return Intl.DateTimeFormat(language, { month: 'short', day: '2-digit', year: 'numeric' }).format(
    date,
  );
};

const normalizeTime = (time: string) => {
  const currentDate = new Date();
  const originalDate = new Date(time);
  return originalDate.getTime() > currentDate.getTime() ? currentDate : originalDate;
};

const TimeAgo: React.FC<{ className?: string; time?: string | Date }> = ({
  className,
  ...props
}) => {
  const { screenshotMode } = useConfiguration();
  const time = typeof props.time === 'string' ? normalizeTime(props.time) : props.time;

  const { language } = useEnvironment();
  const formatRelativeTime = React.useMemo(
    () =>
      createRtfFormatter(
        language,
        { floorSeconds: true, extraShortExpression: false },
        { localeMatcher: 'best fit', numeric: 'always', style: 'short' },
      ),
    [language],
  );
  return time ? (
    <TextExtended className={className} data-hook={TIME_AGO}>
      {(() => {
        if (screenshotMode) {
          return '<time>';
        }
        if (isLessThanWeek(time)) {
          return formatRelativeTime(time);
        }
        if (isNotCurrentYear(time)) {
          return formatFullDate(language, time);
        }
        return formatShortDate(language, time);
      })()}
    </TextExtended>
  ) : null;
};

export default TimeAgo;
