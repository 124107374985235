import React from 'react';
import {
  SectionNotificationType as NOTIFICATION_TYPE,
  ButtonSize,
  SectionNotification,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import { classes, st } from './form-error-notification-clickable.st.css';
import forDirection from '../../../hoc/for-direction';
import { SECTION_NOTIFICATION_CTA } from '../../../../common/constants/data-hooks';

const Chevron = forDirection(ChevronRight, ChevronLeft);

export const FormErrorNotificationClickable: React.FC<{
  text: string;
  ctaText: string;
  className?: string;
  onClick?: () => void;
  dataHook?: string;
}> = ({ text, ctaText, className, onClick, dataHook }) => {
  return (
    <SectionNotification
      type={NOTIFICATION_TYPE.error}
      className={st(classes.root, className)}
      data-hook={dataHook}
    >
      <SectionNotification.Text className={classes.text}>{text}</SectionNotification.Text>
      {onClick && (
        <SectionNotification.Button
          className={classes.cta}
          suffixIcon={<Chevron />}
          onClick={onClick}
          size={ButtonSize.tiny}
          type="text"
          priority={ButtonPriority.basic}
          data-hook={SECTION_NOTIFICATION_CTA}
        >
          {ctaText}
        </SectionNotification.Button>
      )}
    </SectionNotification>
  );
};
