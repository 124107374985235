import React from 'react';
import { TextField as TextFieldTPA, TextFieldProps } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { classes, style } from './text-field.st.css';

export const TextField: React.FC<TextFieldProps> = (props) => {
  const t = useTranslate();
  return (
    <TextFieldTPA
      {...props}
      clearButtonAriaLabel={t('clear-button.aria-label')}
      className={style(classes.root, props.className)}
      newErrorMessage
    />
  );
};
