import { useEffect, useMemo } from 'react';
import { DEFAULT_CONFIG, highlightSelector } from '../../../common/services/highlight-utils';
import { useReviewFormState } from './review-form-state';
import { uniqueId } from 'lodash';

const HIGHLIGHT_CONFIG = {
  ...DEFAULT_CONFIG,
  shouldHighlightBackground: false,
};

const buildIds = (resourceId: string) => ({
  notification: `form-error-${resourceId}`,
  name: `form-name-${resourceId}`,
  email: `form-email-${resourceId}`,
  rating: `form-rating-${resourceId}`,
  title: `form-title-${resourceId}`,
  body: `form-body-${resourceId}`,
  media: `form-media-${resourceId}`,
});

const fieldOrder = [
  'name' as const,
  'email' as const,
  'rating' as const,
  'title' as const,
  'body' as const,
  'media' as const,
];

export const useHighlightForm = (state: ReturnType<typeof useReviewFormState>) => {
  const ids = buildIds(useMemo(() => uniqueId('review-form'), []));
  useEffect(() => {
    if (state.validationError) {
      const invalidFields = fieldOrder.filter((f) =>
        state.validationError?.invalidFields.includes(f),
      );
      highlightSelector(`#${ids[invalidFields[0]]}`, HIGHLIGHT_CONFIG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.validationError)]);
  useEffect(() => {
    if (state.submissionError) {
      highlightSelector(`#${ids.notification}`, HIGHLIGHT_CONFIG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submissionError]);
  return ids;
};
