import React from 'react';
import { CancelResult } from '../../common/common-types';
import {
  useModal,
  DiscardConfirmationModal,
  DiscardChangesConfirmationModal,
} from '../components/modals';

export type CancelFlow =
  | {
      type: 'PROMPT';
      prompt: () => Promise<'CONFIRM_DISCARD' | 'CANCEL_DISCARD'>;
      onConfirm: () => void;
      highlightSelector?: string;
    }
  | { type: 'SILENT_CANCEL'; cleanup: () => void };

export type ResolveCancelFlow = () => CancelFlow;

export const useCancelFlow = ({
  onCancel,
  selector,
  isReply,
}: {
  onCancel: () => CancelResult;
  selector?: string;
  isReply: boolean;
}) => {
  const showModal = useModal();
  return (): CancelFlow => {
    const result = onCancel();

    if (result.type === 'SILENT_CANCEL') {
      return result;
    }

    const ConfirmationModal =
      result.discard === 'COMMENT' ? DiscardConfirmationModal : DiscardChangesConfirmationModal;

    return {
      type: 'PROMPT',
      onConfirm: result.onConfirm,
      highlightSelector: selector,
      prompt: () =>
        new Promise<'CONFIRM_DISCARD' | 'CANCEL_DISCARD'>((resolve) => {
          showModal((toggle) => (
            <ConfirmationModal
              toggle={toggle}
              isReply={isReply}
              onClose={(hasConfirmed) => {
                if (hasConfirmed) {
                  resolve('CONFIRM_DISCARD');
                } else {
                  resolve('CANCEL_DISCARD');
                }
              }}
            />
          ));
        }),
    };
  };
};

export const useCancelFlowExecutor = (resolveCancelFlow: ResolveCancelFlow) => {
  return () => {
    const result = resolveCancelFlow();
    if (result.type === 'SILENT_CANCEL') {
      result.cleanup();
      return;
    } else {
      const { prompt, onConfirm } = result;
      prompt().then((promptResult) => promptResult === 'CONFIRM_DISCARD' && onConfirm());
    }
  };
};
