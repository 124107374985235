import {
  StyleParamType,
  createStylesParams,
  wixColorParam,
  wixFontParam,
  ISettingsColor,
  IStyleParam,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import parseColor from 'color';

export type VisibilityStylesParams = {
  isHeaderTitleEnabled: StyleParamType.Boolean;
  isFilterEnabled: StyleParamType.Boolean;
  isSortEnabled: StyleParamType.Boolean;
  isReviewDateEnabled: StyleParamType.Boolean;
};

export const visibilityStyleParams: {
  [P in keyof VisibilityStylesParams]: Omit<IStyleParam<VisibilityStylesParams[P]>, 'name'>;
} = {
  isHeaderTitleEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isFilterEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isSortEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isReviewDateEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
};

export type AllStylesParams = {
  // -------Header styles-------//
  headerActiveStarColor: StyleParamType.Color;
  headerInactiveStarColor: StyleParamType.Color;
  progressBarColor: StyleParamType.Color;
  progressBarBgColor: StyleParamType.Color;
  progressBarCornerRadius: StyleParamType.Number;
  starBarchartTextFont: StyleParamType.Font;
  starBarchartTextFontSize: StyleParamType.Number;
  starBarchartTextColor: StyleParamType.Color;
  headerTitleTextFont: StyleParamType.Font;
  headerTitleTextFontSize: StyleParamType.Number;
  headerTitleTextColor: StyleParamType.Color;
  headerScoreTextFont: StyleParamType.Font;
  headerScoreTextColor: StyleParamType.Color;
  headerButtonBgColor: StyleParamType.Color;
  headerButtonBorderColor: StyleParamType.Color;
  headerButtonBorderWidth: StyleParamType.Number;
  headerButtonCornerRadius: StyleParamType.Number;
  headerButtonTextFont: StyleParamType.Font;
  headerButtonTextColor: StyleParamType.Color;
  headerButtonHoverBgColor: StyleParamType.Color;
  headerButtonHoverBorderColor: StyleParamType.Color;
  headerButtonHoverTextColor: StyleParamType.Color;
  headerStarSize: StyleParamType.Number;
  // -------Review list styles-------//
  reviewActiveStarColor: StyleParamType.Color;
  reviewInactiveStarColor: StyleParamType.Color;
  reviewTitleTextColor: StyleParamType.Color;
  reviewTitleTextFont: StyleParamType.Font;
  reviewTitleTextFontSize: StyleParamType.Number;
  reviewBodyTextColor: StyleParamType.Color;
  reviewBodyTextFont: StyleParamType.Font;
  reviewBodyTextFontSize: StyleParamType.Number;
  sortAndFilterTextFont: StyleParamType.Font;
  sortAndFilterTextFontSize: StyleParamType.Number;
  sortAndFilterTextColor: StyleParamType.Color;
  helpfulVotesTextColor: StyleParamType.Color;
  helpfulVotesTextFont: StyleParamType.Font;
  verifiedBadgeTextColor: StyleParamType.Color;
  verifiedBadgeColor: StyleParamType.Color;
  activeHelpfulVoteColor: StyleParamType.Color;
  reviewSeparatorColor: StyleParamType.Color;
  linkButtonTextFont: StyleParamType.Font;
  linkButtonTextColor: StyleParamType.Color;
  cardsSpacing: StyleParamType.Number;
  reviewHorizontalPadding: StyleParamType.Number;
  reviewCardVerticalPadding: StyleParamType.Number;
  reviewListVerticalPadding: StyleParamType.Number;
  reviewCardCornerRadius: StyleParamType.Number;
  reviewCardBorderWidth: StyleParamType.Number;
  reviewCardBorderColor: StyleParamType.Color;
  reviewCardColor: StyleParamType.Color;
  reviewCardShadowColor: StyleParamType.Color;
  reviewShadowBlur: StyleParamType.Number;
  reviewShadowDistance: StyleParamType.Number;
  reviewShadowDirection: StyleParamType.Number;
  reviewShadowX: StyleParamType.Number;
  reviewShadowY: StyleParamType.Number;
  reviewStarSize: StyleParamType.Number;
  // -------Review form styles-------//
  formStarColor: StyleParamType.Color;
  formInputBgColor: StyleParamType.Color;
  formInputBorderColor: StyleParamType.Color;
  formInputCornerRadius: StyleParamType.Number;
  formInputBorderWidth: StyleParamType.Number;
  formCornerRadius: StyleParamType.Number;
  formBorderColor: StyleParamType.Color;
  formBorderWidth: StyleParamType.Number;
  formBgColor: StyleParamType.Color;
  formInputTextFont: StyleParamType.Font;
  formInputTextFontSize: StyleParamType.Number;
  formInputTextColor: StyleParamType.Color;
  formLabelTextFont: StyleParamType.Font;
  formLabelTextColor: StyleParamType.Color;
  formButtonBgColor: StyleParamType.Color;
  formButtonTextFont: StyleParamType.Font;
  formButtonTextColor: StyleParamType.Color;
  formButtonCornerRadius: StyleParamType.Number;
  formSidePadding: StyleParamType.Number;
  formTopBottomPadding: StyleParamType.Number;
  // -------Hover state styles-------//
  hoverFormStarColor: StyleParamType.Color;
  hoverFormInputBgColor: StyleParamType.Color;
  hoverFormInputBorderColor: StyleParamType.Color;
  hoverFormInputTextColor: StyleParamType.Color;
  // -------Error State styles-------//
  errorFormColor: StyleParamType.Color;
  // -------Widget styles-------//
  widgetBgColor: StyleParamType.Color;
  widgetBorderColor: StyleParamType.Color;
  widgetBorderWidth: StyleParamType.Number;
  widgetCornerRadius: StyleParamType.Number;
  widgetSidePaddings: StyleParamType.Number;
  widgetVerticalPaddings: StyleParamType.Number;
} & VisibilityStylesParams;

export type StylesParams = typeof styleParams;

const reviewTitleTextFontDefaultFontSize = 20;
const reviewBodyTextFontDefaultFontSize = 16;
const styleParams = createStylesParams<AllStylesParams>({
  // -------Header styles-------//
  headerActiveStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  headerInactiveStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  progressBarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  progressBarBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  progressBarCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  headerTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32 }),
  },
  // for mobile
  headerTitleTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const headerTitleTextFont = props.getStyleParamValue(styleParams.headerTitleTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return headerTitleTextFont.size;
    },
  },
  headerTitleTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  headerScoreTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: (props) =>
      wixFontParam('Page-title', { size: props.isMobile ? 20 : 28 })(props),
  },
  headerScoreTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  starBarchartTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 12 }),
  },
  // for mobile
  starBarchartTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const starBarchartTextFont = props.getStyleParamValue(styleParams.starBarchartTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return starBarchartTextFont.size;
    },
  },
  starBarchartTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  headerButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  headerButtonTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  headerButtonBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  headerButtonCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  cardsSpacing: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewHorizontalPadding: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewCardVerticalPadding: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewListVerticalPadding: { type: StyleParamType.Number, getDefaultValue: () => 24 },
  reviewCardCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 8 },
  reviewCardBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  headerButtonHoverBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBgColor = props.getStyleParamValue(styleParams.headerButtonBgColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(
        headerButtonBgColor ??
          // Sometimes 'getStyleParamValue' returns undefined :(
          wixColorParam('color-1')(props),
        0.7,
      );
    },
  },
  headerButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBorderColor = props.getStyleParamValue(
        styleParams.headerButtonBorderColor,
        {
          isMobile: props.isMobile,
          isRTL: props.isRTL,
        },
      );
      return applyOpacity(headerButtonBorderColor ?? wixColorParam('color-8')(props), 0.7);
    },
  },
  headerButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonTextColor = props.getStyleParamValue(styleParams.headerButtonTextColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(headerButtonTextColor ?? wixColorParam('color-8')(props), 0.7);
    },
  },
  headerStarSize: { type: StyleParamType.Number, getDefaultValue: () => 26 },
  // -------Review list styles-------//
  reviewActiveStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewInactiveStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  reviewTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  reviewTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: reviewTitleTextFontDefaultFontSize }),
  },
  reviewTitleTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const size = props.getStyleParamValue(styleParams.reviewTitleTextFont).size as number;
      return size !== reviewTitleTextFontDefaultFontSize
        ? size // If user updated the font from default we return the user changes
        : reviewTitleTextFontDefaultFontSize; // Otherwise we return the default
    },
  },
  reviewBodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  reviewBodyTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: reviewBodyTextFontDefaultFontSize }),
  },
  reviewBodyTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const size = props.getStyleParamValue(styleParams.reviewBodyTextFont).size as number;
      return size !== reviewBodyTextFontDefaultFontSize
        ? size // If user updated the font from default we return the user changes
        : reviewBodyTextFontDefaultFontSize; // Otherwise we return the default
    },
  },
  sortAndFilterTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  sortAndFilterTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const sortAndFilterTextFont = props.getStyleParamValue(styleParams.sortAndFilterTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return sortAndFilterTextFont.size;
    },
  },
  sortAndFilterTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  helpfulVotesTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  helpfulVotesTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  verifiedBadgeTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  verifiedBadgeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.2),
  },
  activeHelpfulVoteColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewSeparatorColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  linkButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  linkButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewCardShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  reviewCardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  reviewCardColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  reviewShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 8;
    },
  },
  reviewShadowX: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  reviewShadowY: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 4;
    },
  },
  reviewShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 4;
    },
  },
  reviewShadowDirection: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 180;
    },
  },
  reviewStarSize: { type: StyleParamType.Number, getDefaultValue: () => 18 },
  // -------Review form styles-------//
  formStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  formInputBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formInputBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  formInputCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    // only changed in desktop, keep this always to zero in mobile
    inheritDesktop: false,
  },
  formButtonCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    // mobile should not follow desktop
    inheritDesktop: false,
  },
  formTopBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    // mobile should not follow desktop
    inheritDesktop: false,
  },
  formBgColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  formBorderColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5', 0.3) },
  formInputBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formInputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  // mobile
  formInputTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const formInputTextFont = props.getStyleParamValue(styleParams.formInputTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return formInputTextFont.size;
    },
  },
  formInputTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formLabelTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  formLabelTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formButtonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  formButtonTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  // -------Hover state styles-------//
  hoverFormStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  hoverFormInputBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hoverFormInputBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  hoverFormInputTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  // -------Error State styles-------//
  errorFormColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      value: parseColor('#DF3131').toString(),
      opacity: 1,
      name: null,
    }),
  },
  // -------Widget styles-------//
  widgetBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  widgetCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  widgetSidePaddings: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  widgetVerticalPaddings: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  ...visibilityStyleParams,
});

const applyOpacity = (c: ISettingsColor, o: number): ISettingsColor => {
  const color = parseColor(c.value);
  const originalOpacity = color.alpha();
  const newOpacity = originalOpacity * o;
  return {
    value: color.alpha(newOpacity).toString(),
    opacity: newOpacity,
    name: null,
  };
};
export default styleParams;

export const customCssVars: CustomCssVarsFn = ({ styleParams: styles }) => {
  return {
    headerTitleDisplay: styles.booleans.isHeaderTitleEnabled ? 'block' : 'none',
    filterDisplay: styles.booleans.isFilterEnabled ? 'block' : 'none',
    sortDisplay: styles.booleans.isSortEnabled ? 'block' : 'none',
    dateDisplay: styles.booleans.isReviewDateEnabled ? 'inline' : 'none',
  };
};
