import React from 'react';
import { State } from '../../../common/store/store-types';
import { applyPatch } from './apply-patch';
import { StateWrapper } from '../../../common/common-types';
import { unreachable } from '~/ts-utils';

// @TODO should we have 'undefined' as one of state values in its type?
const STATE_FALLBACK = undefined;

/*
 * Hook which receives state change 'messages' via stateWrapper and emmits synchronized
 * state with as much references preserved as possible thus allowing easy render optimizations.
 * if some messages are lost (because controller setProps might get optimized) it will
 * request full state as a fallback
 */
export const useOptimizedState = (
  stateWrapper: StateWrapper | undefined,
  requestFullState: () => void,
): State | undefined => {
  const lastPatchNumber = React.useRef(0);
  const state = React.useRef<State | null>(null);

  if (!stateWrapper) {
    // controller is late
    return state.current ?? STATE_FALLBACK;
  }
  switch (stateWrapper.type) {
    case 'SET': {
      // first render with initial state OR state refetch after failure
      state.current = stateWrapper.state;
      lastPatchNumber.current = 0;
      return stateWrapper.state;
    }
    case 'PATCH': {
      if (!state.current) {
        console.error('Receiving patch before init', stateWrapper);
        lastPatchNumber.current = stateWrapper.patchNumber;
        requestFullState();
        return STATE_FALLBACK;
      }
      if (lastPatchNumber.current === stateWrapper.patchNumber) {
        // new render with same state wrapper
        return state.current;
      }
      if (lastPatchNumber.current + 1 !== stateWrapper.patchNumber) {
        lastPatchNumber.current = stateWrapper.patchNumber;
        requestFullState();
        return state.current;
      }

      lastPatchNumber.current = stateWrapper.patchNumber;
      const prevState = state.current;
      state.current = applyPatch(prevState, stateWrapper.patch);

      return state.current;
    }
    default:
      throw unreachable(stateWrapper);
  }
};
